import React from 'react';
import styles from './privacyPolicyPage.module.scss';

const PrivacyPolicyPage: React.FC = () => {
    return (
        <div className={styles.page_container}>
            <h1 className={styles.title}>Privacy Policy</h1>
            <div className={styles.content}>
                <div className={styles.effective_date}>Effective Date: 06.01.2025</div>

                <section>
                    <h2>1. Introduction</h2>
                    <p>
                        Welcome to Pictographs, a photo app game blending creativity and social interaction. Your privacy is important, and we are committed to protecting your personal data. This Privacy Policy explains how we collect, use, share, and safeguard your information, as well as your rights regarding your data. By accessing or using Pictographs, you agree to the practices outlined in this Privacy Policy.
                    </p>
                </section>

                <section>
                    <h2>2. Information We Collect</h2>
                    <p>
                        We collect information to provide, improve, and optimize our platform and services. The types of information we collect include:
                    </p>
                    <div className={styles.info_box}>
                        <h4>Personal Information</h4>
                        <p>
                            When you sign up, create an account, or interact with our platform, we may collect information such as your name, email address, phone number, or other identifiable details necessary for account creation and user verification.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Usage Data</h4>
                        <p>
                            We collect information about how you interact with Pictographs, including your activities, preferences, and the features you use. This includes logs, analytics data, and time spent on specific app sections.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Device Information</h4>
                        <p>
                            Details about the device you use to access the platform, such as the type of device, operating system, IP address, browser type, and unique device identifiers, are collected to ensure compatibility and functionality.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Photographic Content</h4>
                        <p>
                            As a photo-centric platform, we store and process the images or visual content you upload, share, or interact with on Pictographs. These images are not shared without your consent and are processed securely to support the app's features.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Payment Information</h4>
                        <p>
                            If you make in-app purchases or transactions, we may collect payment-related details. Sensitive financial data is handled by trusted third-party payment processors in compliance with industry security standards.
                        </p>
                    </div>
                </section>

                <section>
                    <h2>3. How We Use Your Information</h2>
                    <p>The information we collect is essential for:</p>
                    <div className={styles.info_box}>
                        <h4>Providing and Maintaining Services</h4>
                        <p>
                            To enable the core functionalities of Pictographs and ensure smooth and efficient service delivery.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Personalizing User Experience</h4>
                        <p>
                            Customizing features, recommendations, and content to match your preferences and interests.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Communication</h4>
                        <p>
                            Sending you important updates, announcements, and responding to inquiries or support requests.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Improving and Innovating</h4>
                        <p>
                            Analyzing usage patterns to identify issues, optimize performance, and develop new features or services.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Ensuring Security and Fraud Prevention</h4>
                        <p>
                            Monitoring activities to detect and prevent fraudulent, unauthorized, or harmful behavior on the platform.
                        </p>
                    </div>
                </section>

                <section>
                    <h2>4. Sharing Your Information</h2>
                    <p>
                        We are committed to keeping your data private. However, we may share your information in the following circumstances:
                    </p>
                    <div className={styles.info_box}>
                        <h4>With Service Providers</h4>
                        <p>
                            Trusted third-party vendors may access your data to provide essential services like cloud storage, analytics, and customer support. These vendors comply with strict data protection standards.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>For Legal Compliance</h4>
                        <p>
                            We may disclose your information to comply with applicable laws, regulations, or legal processes.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>During Business Transfers</h4>
                        <p>
                            If Pictographs undergoes a merger, acquisition, or sale of assets, your information may be transferred to the new entity.
                        </p>
                    </div>
                </section>

                <section>
                    <h2>5. Data Security</h2>
                    <p>
                        We use industry-standard security measures, including encryption protocols to protect your personal information from unauthorized access, alteration, disclosure, or destruction. While we strive to safeguard your data, no method of transmission over the Internet or storage is completely secure. We encourage you to take steps to protect your account, such as using strong passwords and securing your devices.
                    </p>
                </section>

                <section>
                    <h2>6. Your Rights and Choices</h2>
                    <p>
                        Depending on your jurisdiction, you may have the following rights regarding your data:
                    </p>
                    <div className={styles.info_box}>
                        <h4>Access and Rectification</h4>
                        <p>
                            Request a copy of your personal data and correct inaccuracies.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Deletion</h4>
                        <p>
                            Request the deletion of your personal information, subject to legal and operational requirements.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Data Portability</h4>
                        <p>
                            Receive your personal data in a commonly used, machine-readable format.
                        </p>
                    </div>
                    <div className={styles.info_box}>
                        <h4>Withdraw Consent</h4>
                        <p>
                            Revoke your consent for data collection and processing, where applicable.
                        </p>
                    </div>
                    <p>
                        To exercise your rights, please contact us at <a href="mailto:contact@pictographs.io">contact@pictographs.io</a>. You can also manage certain data preferences directly within the app settings.
                    </p>
                </section>

                <section>
                    <h2>7. Children's Privacy</h2>
                    <p>
                        Pictographs is not intended for individuals under the age of 18. We do not knowingly collect or process data from children. If we become aware of any such data, we will delete it promptly. Parents or guardians who believe their child has provided personal information should contact us.
                    </p>
                    <p>
                        To prevent unauthorized access by minors, we may employ basic age verification measures during account creation.
                    </p>
                </section>

                <section>
                    <h2>8. Retention of Data</h2>
                    <p>
                        We retain your data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce agreements. For example:
                    </p>
                    <ul>
                        <li>Log and analytics data: Retained for up to 12 months.</li>
                        <li>Photographic content: Retained until explicitly deleted by the user.</li>
                    </ul>
                </section>

                <section>
                    <h2>9. International Data Transfers</h2>
                    <p>
                        As a global platform, your information may be transferred and processed outside your country of residence. We ensure such transfers comply with relevant legal frameworks, such as GDPR Standard Contractual Clauses, to maintain a high level of data protection.
                    </p>
                </section>

                <section>
                    <h2>10. Changes to This Privacy Policy</h2>
                    <p>
                        We may revise this Privacy Policy from time to time. Any changes will be posted on our website with the updated "Effective Date." We encourage you to review this policy periodically to stay informed about how we protect your information.
                    </p>
                </section>

                <section>
                    <h2>11. Contact Us</h2>
                    <p>
                        If you have any questions, concerns, or complaints about this Privacy Policy or our data practices, please contact us at:
                    </p>
                    <p>
                        Email: <a href="mailto:contact@pictographs.io">contact@pictographs.io</a>
                    </p>
                    <p>
                        By using Pictographs, you agree to this Privacy Policy. Your trust is important to us, and we are dedicated to protecting your privacy.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
