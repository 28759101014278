import React, { useEffect } from 'react';
import MainPage from './MainPage/MainPage';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";
import NotFoundPage from './NotFoundPage/NotFoundPage';
import HowToPlayPage from './HowToPlayPage/HowToPlayPage';
import TermsOfUsePage from './TermsOfUsePage/TermsOfUsePage';
import PrivacyPolicyPage from './PrivacyPolicyPage/PrivacyPolicyPage';
import useIsMobile from './Hooks/useIsMobile';
import AOS from 'aos';
import './app.scss';

function App() {
  const isMobile = useIsMobile();
  useEffect(() => {
    AOS.init({
      duration: isMobile ? 500 : 750,
      offset: isMobile ? 150 : 250,
      once: false,
      disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches
    });
  })
  return (
    <div className="app">
      <Header/>
      <main className="main-content">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<MainPage/>}/>
            <Route path='/video' element={<HowToPlayPage/>}/>
            <Route path='/terms' element={<TermsOfUsePage/>}/>
            <Route path='/privacy' element={<PrivacyPolicyPage/>}/>
            <Route path='*' element={<NotFoundPage/>}/>
          </Routes>
        </BrowserRouter>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
