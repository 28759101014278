import { useEffect, useState, useRef } from "react";
import styles from "./footer.module.scss";
import footer from '../../data/footer/footer.json';

function Footer() {
    const [showFixed, setShowFixed] = useState(true);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Show fixed bar when black content starts to be visible
                setShowFixed(!entry.isIntersecting);
            },
            {
                threshold: 0,
                rootMargin: '-44px 0px 0px 0px' // Height of social bar
            }
        );

        if (contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const SocialIcons = () => (
        <div className={styles.footer_social}>
            {footer.map((social, index) => (
                <a key={index} href={social.link} target="_blank" rel="noopener noreferrer">
                    <img src={require('../../data/footer/icons/' + social.icon)} alt={social.icon.split('.')[0]}/>
                </a>
            ))}
        </div>
    );

    return (
        <>
            {showFixed && (
                <div className={styles.fixed_social}>
                    <SocialIcons />
                </div>
            )}
            <footer className={styles.footer}>
                <div className={styles.social_wrapper}>
                    <SocialIcons />
                </div>
                <div ref={contentRef} className={styles.footer_content}>
                    <a href="/terms" className={styles.footer_button}>Terms of Use</a>
                    <a href="/privacy" className={styles.footer_button}>Privacy Policy</a>
                    <div className={styles.footer_copyright}>
                        Copyright 2024-2025 Picto Labs PTE. LTD. All rights reserved.
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;