import React from 'react';
import styles from './termsOfUsePage.module.scss';

const TermsOfUsePage: React.FC = () => {
    return (
        <div className={styles.page_container}>
            <h1 className={styles.title}>Terms of Use</h1>
            <div className={styles.content}>
                <div className={styles.effective_date}>Effective Date: 06.01.2025</div>
                
                <p className={styles.intro}>
                    These Terms of Use ("Terms") are a legally binding agreement between you ("User," "you," or "your") and 
                    Picto Labs PTE. Ltd. ("Pictographs," "we," "us," or "our") regarding your access to and use of our website, 
                    platform, and related services (collectively, the "Services"). Please read these Terms carefully before using 
                    the Services. By accessing or using the Services, you agree to be bound by these Terms.
                </p>

                <p className={styles.warning}>
                    If you do not agree to these Terms, you may not access or use the Services.
                </p>

                <section>
                    <h2>1. Acceptance of Terms</h2>
                    <p>By using Pictographs, you confirm that you are:</p>
                    <ol>
                        <li>At least 18 years old or have reached the age of majority in your jurisdiction.</li>
                        <li>Legally capable of entering into this agreement.</li>
                        <li>Using the Services in compliance with all applicable laws and regulations.</li>
                        <li>Not currently resident in the United States of America, the People's Republic of China, Cuba, 
                            the Crimean Peninsula, Sevastopol, Iran, Afghanistan, Syria, North Korea, Antigua & Barbuda, 
                            Hong Kong, Thailand, Malaysia, India, Canada (specifically in Ontario), or the UAE.</li>
                    </ol>
                    <p>
                        If you are using the Services on behalf of an organization, you represent that you have the authority 
                        to bind that organization to these Terms.
                    </p>
                </section>

                <section>
                    <h2>2. About Pictographs</h2>
                    <p>
                        Pictographs is a community-based photo app game incorporating social media and rewarding players 
                        for their time and effort placed into the game. The interactive user experience encourages user 
                        creativity and engagement with peer-to-peer photography via game quests.
                    </p>
                    <p>
                        Users of the Pictographs platform are given daily quests to complete. Players can earn in-game 
                        currency by completing quests and using it to upgrade their equipment.
                    </p>
                    <p>
                        Players can earn by using their photography skills along with engaging in the Pictographs community. 
                        In addition, users are given a preference to complete photography quests by snapping photos requested 
                        by the platform.
                    </p>
                    <p>
                        Pictographs promote an active and engaging experience for users who enjoy taking pictures with their 
                        mobile devices.
                    </p>
                    <p>
                        Learn more about our mission and features at{' '}
                        <a href="https://pictographs.gitbook.io/pictographs-docs" target="_blank" rel="noopener noreferrer">
                            https://pictographs.gitbook.io/pictographs-docs
                        </a>
                    </p>
                </section>

                <section>
                    <h2>3. Provision of the App</h2>
                    <p>
                        User access to the App is granted on a temporary and conditional basis. The Company retains the right 
                        to modify the App's content or discontinue its availability at any time without prior notice. Users 
                        acknowledge and accept that access to all or portions of the App may be restricted at the Company's discretion.
                    </p>
                    <p>
                        In the event that the App becomes unavailable for an extended duration, the Company is not obligated 
                        to provide compensation or accept any liability, regardless of the cause of the unavailability.
                    </p>
                </section>

                <section>
                    <h2>4. User Accounts</h2>
                    <p>To access certain features of the Services, you must create an account. By creating an account, you agree to:</p>
                    <ol>
                        <li>Provide accurate and complete information during registration.</li>
                        <li>Keep your account credentials secure and confidential.</li>
                        <li>Accept responsibility for all activities under your account.</li>
                        <li>Notify us immediately if you suspect unauthorized access to your account.</li>
                    </ol>
                    <p>Pictographs reserves the right to suspend or terminate accounts that violate these Terms.</p>
                </section>

                <section>
                    <h2>5. Linked Sites</h2>
                    <p>
                        The App may include links to external apps or websites ("Linked Sites") that are not operated or controlled 
                        by the Company. The Company does not endorse, guarantee, or assume responsibility for the Linked Sites, 
                        nor for any loss or damage resulting from your interaction with them. Your use of any Linked Site is 
                        governed by the terms and conditions set by the respective site.
                    </p>
                </section>

                <section>
                    <h2>6. Fees and Payments</h2>
                    <ol>
                        <li>
                            The Company reserves the right to amend the fees and payment methods outlined in this section. 
                            Notification of such changes will be provided in a manner the Company deems appropriate. Alternatively, 
                            updates regarding fees and payment methods may be communicated by posting the information on the 
                            Company's website.
                        </li>
                        <li>
                            Refunds for payments made by users will be processed in accordance with the refund policies of the 
                            applicable app store or marketplace through which the payment was made. Refunds may be provided for 
                            cases where the Service does not function as advertised or in other circumstances as required by 
                            applicable laws and app store policies.
                        </li>
                    </ol>
                </section>

                <section>
                    <h2>7. Prohibited Conduct</h2>
                    <p>
                        When using this content, users must refrain from the following actions. If you are unsure whether a 
                        specific action is prohibited, please reach out to us for guidance:
                    </p>
                    <ol>
                        <li>Transferring, lending, or otherwise providing the purchased content or its usage rights to third parties.</li>
                        <li>Allowing third parties to download, access, or use the content in any unauthorized manner.</li>
                        <li>Using the content in ways that infringe on third-party rights, including but not limited to intellectual 
                            property rights, rights of publicity, privacy, or likeness, as well as other personal or proprietary rights.</li>
                        <li>Misrepresenting ownership of copyrights or any other intellectual property associated with the content.</li>
                        <li>Creating materials with the content that could lead to the establishment of new intellectual property 
                            rights, such as trademarks or designs.</li>
                        <li>Engaging in acts that defame, discriminate against, or damage the reputation or credibility of the 
                            Company or any third party.</li>
                        <li>Using the content for activities linked to adult entertainment, pornography, fraudulent business practices, 
                            dating platforms, organized crime, or any other ventures that contravene public norms or ethics, regardless 
                            of their legality.</li>
                        <li>Filming or using the content in ways that breach laws or violate societal standards of decency.</li>
                        <li>Repurposing the content to develop or offer services similar to those provided by this App.</li>
                        <li>Engaging in any other actions explicitly prohibited by these Terms or deemed inappropriate based on 
                            clearly defined criteria provided by the Company.</li>
                    </ol>
                    <p>
                        Pictographs reserves the right to investigate and take appropriate action, including suspending or 
                        terminating accounts, for violations of this section.
                    </p>
                </section>

                <section>
                    <h2>8. Ownership and Intellectual Property</h2>
                    <h3>8.1 Platform Content</h3>
                    <p>
                        All intellectual property rights associated with Pictographs—including, but not limited to, the software, 
                        branding elements, designs, and other proprietary materials—are owned solely by Pictographs or our licensors. 
                        These rights are protected under applicable intellectual property laws. Any unauthorized use, reproduction, 
                        modification, or distribution of Pictographs' intellectual property, whether in part or in whole, is strictly 
                        prohibited and may result in legal action.
                    </p>

                    <h3>8.2 User-Generated Content</h3>
                    <p>
                        When you upload or create content on the platform, you retain full ownership of the intellectual property 
                        rights associated with that content. However, by sharing your content on Pictographs, you grant us a 
                        non-exclusive, worldwide, royalty-free license to use, display, reproduce, and distribute your content 
                        solely within the platform and as required to deliver the Services. This license allows us to showcase 
                        your work to other users, promote user engagement, and maintain the functionality of the platform.
                    </p>
                    <p>
                        By uploading content, you affirm that you have the necessary rights and permissions to grant us this 
                        license. You further warrant that your content does not infringe upon the intellectual property or other 
                        rights of any third party and complies with all applicable laws and regulations.
                    </p>
                    <p>
                        To maintain a safe and engaging environment, Pictographs moderates user-generated content. Content that 
                        violates these Terms may be removed, and users responsible for such content may face account suspension 
                        or termination.
                    </p>
                </section>

                <section>
                    <h2>9. Third-Party Services</h2>
                    <p>
                        Pictographs may integrate with third-party tools, platforms, or services to enhance your experience. 
                        These third-party services operate independently, and we do not own, manage, or control them.
                    </p>
                    <p>
                        We make no representations or warranties regarding the functionality, availability, or reliability of 
                        these third-party services. Your interactions with them are solely at your discretion and subject to 
                        the terms and conditions established by the respective third-party providers.
                    </p>
                    <p>
                        For more information on how your data is handled when interacting with third-party services, please 
                        refer to our Privacy Policy, which outlines data sharing practices in compliance with applicable regulations.
                    </p>
                </section>

                <section>
                    <h2>10. Disclaimer of Warranties</h2>
                    <p>
                        The Services provided by Pictographs are offered "as is" and "as available," without any guarantees or 
                        warranties of any kind. We disclaim all express, implied, or statutory warranties, including but not 
                        limited to:
                    </p>
                    <ol>
                        <li>Warranties of merchantability.</li>
                        <li>Fitness for a particular purpose.</li>
                        <li>Non-infringement of intellectual property.</li>
                    </ol>
                    <p>
                        We do not guarantee uninterrupted access to the Services, error-free operation, or the accuracy and 
                        reliability of the information provided on the platform.
                    </p>
                </section>

                <section>
                    <h2>11. Limitation of Liability</h2>
                    <p>
                        To the fullest extent allowed by law, Pictographs, its affiliates, and service providers are not liable 
                        for any:
                    </p>
                    <ol>
                        <li>Indirect, incidental, special, or consequential damages.</li>
                        <li>Loss of profits, data, or digital assets.</li>
                        <li>Damages resulting from unauthorized access to your account, wallet, or content.</li>
                    </ol>
                    <p>
                        Our maximum liability to you, regardless of the basis of the claim, will not exceed the total amount 
                        you paid to access the Services within the 12 months preceding the incident.
                    </p>
                </section>

                <section>
                    <h2>12. Indemnification</h2>
                    <p>
                        You agree to indemnify, defend, and hold harmless Pictographs, its affiliates, and their employees or 
                        representatives from any claims, damages, or liabilities arising from:
                    </p>
                    <ol>
                        <li>Your use of the Services.</li>
                        <li>Your violation of these Terms.</li>
                        <li>Any infringement of third-party rights caused by your content or conduct.</li>
                    </ol>
                    <p>
                        You are solely responsible for resolving disputes with third parties caused by your actions or use of 
                        the platform.
                    </p>
                </section>

                <section>
                    <h2>13. Termination</h2>
                    <p>
                        Pictographs reserves the right to suspend or terminate your access to the Services at its discretion, 
                        including but not limited to cases involving:
                    </p>
                    <ol>
                        <li>Violations of these Terms.</li>
                        <li>Actions that harm the platform, its users, or its reputation.</li>
                        <li>Compliance with legal or regulatory obligations.</li>
                    </ol>
                    <p>
                        Users whose accounts are suspended or terminated will be notified and provided with an opportunity to 
                        appeal such decisions unless the violation is deemed severe (e.g., illegal activity).
                    </p>
                    <p>
                        You may terminate your account at any time by contacting us. However, termination does not exempt you 
                        from responsibilities or liabilities accrued under these Terms before the termination date.
                    </p>
                </section>

                <section>
                    <h2>14. Governing Law and Dispute Resolution</h2>
                    <p>
                        These Terms are governed by the laws of Singapore, excluding its conflict of laws principles. In the 
                        event of a dispute, you agree to resolve it through binding arbitration conducted in Singapore unless 
                        prohibited by law.
                    </p>
                    <p>
                        Nothing in this section prevents users from asserting their legal rights in jurisdictions where 
                        arbitration clauses are unenforceable.
                    </p>
                </section>

                <section>
                    <h2>15. Changes to These Terms</h2>
                    <p>
                        We reserve the right to modify these Terms at any time. Any updates will be posted on our website, and 
                        the "Effective Date" at the top of the Terms will reflect the latest revision. Continued use of the 
                        Services after changes are posted constitutes your acceptance of the updated Terms.
                    </p>
                </section>

                <section>
                    <h2>16. Contact Us</h2>
                    <p>If you have any questions or concerns about these Terms, feel free to reach out:</p>
                    <p>
                        Email:{' '}
                        <a href="mailto:contact@pictographs.io">contact@pictographs.io</a>
                    </p>
                </section>
            </div>
        </div>
    );
};

export default TermsOfUsePage;